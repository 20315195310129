import { Grid, Typography } from "@material-ui/core";
//STYLES
import useStyles from "./style"; 

export default function PageHeader(){
  const classes = useStyles();
  return (
    <Grid container direction="column" className={classes.container}>
      <Grid item>
        <Typography variant="h1" gutterBottom align="center">CIDU BLOG</Typography>
      </Grid>

      <Grid item>
        <Typography
          variant="body1"
          color="textSecondary"
          component="p"
          align="center" >
          Conozca todo acerca de los Avalúos Comerciales.
          <br />  
           Si tiene alguna duda o pregunta puede contactarnos en          
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://cidu.com.co/contacto"
            className={classes.link}
          > nuestra Página Web.</a>  
        </Typography>
      </Grid>
    </Grid>
  );
}