import Link from "../link";
import moment from "moment";
import "moment/locale/es";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
//STYLES
import useStyles from "./style"; 

export default function Post(props) {
  const { title, subtitle, authorName, slug, date, coverImage,} = props;
  const classes = useStyles();
  moment.locale('es')

  return (
    <Link href="/blog/[slug]" as={`/blog/${slug}`} className={classes.linkContainer}>
      <Card className={classes.root} elevation={6}> 
        <CardHeader
          avatar={
            <Avatar
              aria-label="avator image"
              className={classes.avatar}
              style={{ backgroundImage: "url('/images/logo512.png')",  pointerEvents: 'none'}} /> }  // `url(${authorImage})`  //fields.author.fields.image.fields.file.url
          title={authorName}
          subheader={moment(date).format("MMMM Do YYYY").toUpperCase()}
          subheaderTypographyProps={{ noWrap: true, className: classes.typographyCard}}
          titleTypographyProps={{ noWrap: true, className: classes.typographyCard}} />

        <CardMedia className={classes.media} image={coverImage} title={title} />
        <CardContent>
          <Typography variant="h2" gutterBottom noWrap className={classes.tittle}>{title}</Typography>
          <Typography variant="body1" color="textSecondary" noWrap component="p" className={classes.tittle}>{subtitle}</Typography>
        </CardContent>
      </Card>
    </Link>
  );
}