import Post from "../components/post";
import Layout from "../components/layout/layout";
import PageHeader from "../components/pageHeader";
import { Container, Grid } from "@material-ui/core";
import { getAllPosts } from "../lib/index";

export async function getStaticProps() {
  const posts = await getAllPosts();
  return { revalidate: 1, props: { posts } };
}

export default function Index(props) {

  const { posts } = props; 
  
  return (
    <>
      <Layout
        title="Cidu Blog"
        description="Este es un blog diseñado por CIDU S.A.S con el fin de explicar, enseñar y dar a entender todas las metodologías para el desarrollo de Avalúos Comerciales"  >
        <Container maxWidth="lg">
          <PageHeader />
          <Grid container spacing={4}>
            {posts?.map(({ fields }) => (
              <Grid item key={fields.slug} xs={12} md={4}>
                <Grid container>
                  <Post
                    title={fields.titulo}
                    subtitle={fields.subtitulo}
                    authorName={fields.nombre} 
                    slug={fields.slug}
                    date={fields.fecha}
                    coverImage={fields.imgenes.fields.file.url} 
                  />
                </Grid>
              </Grid>
            ))} 
          </Grid>
        </Container>
      </Layout>
    </>
  );
}
