import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
    pointerEvents: 'none',
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    color: "transparent",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  root: {
    transition: "all 0.3s",
    "&:hover": {
      boxShadow: "0px 0px 15px 1.5px rgba(0,0,0,0.45)",
      transform: "translateY(-2px)",
    },
  },

  linkContainer:{
    //width: ''
    width: '-webkit-fill-available'
  }, 
  typographyCard: {
    textOverflow: "ellipsis !important",
    //backgroundColor: 'red',
    overflow: "hidden",
    "-webkit-box-orient": "vertical",
    paddingRight: '36%',
  },
  tittle:{
    textOverflow: "ellipsis !important",
    //backgroundColor: 'red',
    overflow: "hidden",
    "-webkit-box-orient": "vertical"
  }



}));
export default useStyles;